/*
---------------- 
    PREMENNÉ
----------------
*/

body {
  --page-width: 1120px;
  --tablet: 1250px;
  --mobile: 800px;
}

html {
  scroll-behavior: smooth;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated2 {
  /* -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s; */
  -webkit-animation-fill-mode: linear;
  animation-fill-mode: linear;
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.openBox {
  -webkit-animation-name: openBox;
  animation-name: openBox;
}

.delay-50ms {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.delay-100ms {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
.delay-150ms {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
.hidden {
  opacity: 0;
}

@keyframes fadeInLeft {
  0%  {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
} 

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
  }
} 

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} 

@keyframes openBox {
  0%  {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
} 

@media only screen and (max-width: 900px){
  @keyframes openBox {
    0%  {
      height: 0;
      /* -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      transform: translateY(-100%); */
    }
    100% {
      height: 100%;
      /* -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); */
    }
  } 
}


/*
---------------- 
    GLOBAL
----------------
*/

body {
  margin: 0px;
  padding: 0;
  font-family: "Inter",sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-family: "Saira Condensed",sans-serif;
  text-transform: uppercase;
  color: #000;
  line-height: 100px;
  font-weight: 500;
  letter-spacing: 0%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

/*
---------------- 
    HEADER
----------------
*/

.header__bg {
  background-color: #000;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
}

.header__box {
  position: relative;
  max-width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header__box::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("assets/images/main.jpg");
  background-position: center 75%;
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: brightness(0.5); */
  opacity: 40%;
}

.nav__box {
  position: relative;
  padding-top: 15px;
  margin: 0 auto;
  width: 90%;
  max-width: var(--page-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__title {
  color: #fff;
  font-size: 40px; 
  text-transform: uppercase;
  font-family: "Saira Condensed",sans-serif;
}

.nav__list {
  display: flex;
  align-items: center;
  gap: 28px;
  line-height: 1;
}

.nav__list_item {
  color: #fff;
  font-family: "Saira Condensed",sans-serif;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
}

.nav__list_item:last-child {
  padding: 5px 20px;
  background: #D22626;
}

/* ---------- */

.header__title_box {
  position: relative;
  width: 90%;
  max-width: var(--page-width);
  align-items: stretch;
  margin: 0 auto;
  display: flex;
  padding-top: 80px;
}

.header__title {
  color: #fff;
  font-size: 68px;
  line-height: 120%;
}

@media only screen and (max-width: 950px){
  .header__box {
    height: 400px;
  }
  .nav__box {
    justify-content: center;
  }
  .Nav {
    display: none;
  }
  .header__title_box {
    padding-top: 30px;
    justify-content: center;
  }
  .header__title {
    font-size: 48px;
    line-height: 120%;
    text-align: center;
  }
}

@media only screen and (max-width: 650px){
  .header__title_box {
    padding-top: 60px;
  }
  .header__title {
    font-size: 30px;
    line-height: 120%;
    text-align: center;
  }
}

/* ---------- */

.countdown {
  display: flex;
  justify-content: center;
  position: relative;
}

.countdown__box {
  position: absolute;
  top: -108px;
  width: fit-content;
  padding: 40px;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000025;
}

.countdown__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.countdown__number_box {
  background-color: #D22626;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 100px;
  border-radius: 10px;
}

.countdown__number {
  color: #fff;
  font-family: "Saira Condensed",sans-serif;
  font-weight: 400;
  font-size: 48px;
}

.countdown__text {
  color: #000;
  font-family: "Saira Condensed",sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

@media only screen and (max-width: 950px){
  .countdown__box {
    top: -80px;
    padding: 20px;
    gap: 20px;
  }
  .countdown__item {
    gap: 4px;
  }
  .countdown__number_box {
    width: 60px;
    height: 60px;
  }
  
  .countdown__number {
    font-size: 24px;
  }
  
  .countdown__text {
    font-size: 16px;
  }
}

/*
---------------- 
  INTRODUCTION
----------------
*/

.introduction {
  display: flex;
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  margin-top: 175px;
  margin-bottom: 100px;
  gap: 100px;
}

.review__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 55%;
  gap: 25px;
}

.review__bg {
  background-color: #000;
  border-radius: 10px;
  position: relative;
}

.review__card_1,
.review__card_2,
.review__card_3{
  padding: 30px 30px;
  height: 250px;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review__card_1::before,
.review__card_2::before,
.review__card_3::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 50%;
}

.review__card_1::before {
  background-image: url("assets/images/review_1.jpg");
}
.review__card_2::before {
  background-image: url("assets/images/review_2.jpg");
}
.review__card_3::before {
  background-image: url("assets/images/review_3.jpg");
}


.review__card_text {
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.review__persons_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.review__person {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.review__img {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 115px;
  height: 115px;
  border-radius: 100px;
}

.review_active {
  box-shadow: 0px 0px 15px 0px black;
  /* border: 3px solid #D22626; */
}

.img_1 {
  background-color: #000;
  background-image: url("assets/images/img1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img_2 {
  background-color: #000;
  background-image: url("assets/images/img2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img_3 {
  background-color: #000;
  background-image: url("assets/images/img3.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.review__title {
  color: #000;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

.review__subtitle {
  color: #707070;
  font-size: 10px;
  font-weight: 500;
}

/* ---------- */

.introduction__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 45%;
}

.introduction__title {
  line-height: 1;
  font-size: 40px;
  font-weight: bold;
  color: #000;
}

.introduction__text {
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  color: #343434;
}

.introduction__button {
  font-size: 24px;
  font-family: "Saira Condensed",sans-serif;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #D22626;
  text-transform: uppercase;
  cursor: pointer;
}

@media only screen and (max-width: 1250px){
  .introduction {
    margin-top: 150px;
    gap: 50px;
  }
}

@media only screen and (max-width: 900px){
  .introduction {
    margin-top: 90px;
    margin-bottom: 50px;
    gap: 35px;
    flex-direction: column-reverse;
    align-items: center;
  }
  .review__box {
    width: 100%;
  }
  .review__card {
    padding: 40px 15px;
  }
  .introduction__box{
    width: 100%;
    align-items: center;
  }
  .introduction__text {
    text-align: center;
    font-size: 14px;
  }
  .review__card_text {
    font-size: 16px;
  }
  .review__persons_box {
    gap: 20px;
  }
  .review__img {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: 650px){
  .review__card_3{
    min-height: 250px;
    height: 100%;
  }
}

/*
---------------- 
     VIDEO
----------------
*/

.video {
  background-color: #000;
}

.video__bg {
  position: relative;
  height: 700px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video__bg::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("assets/images/video.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 70%;
}

.video__trailer {
  z-index: 5;
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 700px;
  border: 0;
}

.video__content {
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.video__title {
  font-size: 48px;
  font-weight: bold;
  font-family: "Inter",sans-serif;
  color: #fff;
}

.video__button {
  width: 100px;
  height: 100px;
  cursor: pointer;
  background-image: url("assets/images/video-button.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video__more {
  font-size: 24px;
  font-weight: bold;
  background-color: #D22626;
  text-transform: uppercase;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1250px){
  .video__bg {
    height: 500px;
  }
  .video__trailer {
    height: 500px;
  }
}

@media only screen and (max-width: 900px){
  .video__bg {
    height: 400px;
  }
  .video__trailer {
    height: 400px;
  }
  .video__content{
    gap: 35px;
  }
  .video__title{
    font-size: 32px;
    line-height: 130%;
    text-align: center;
  }
  .video__button {
    width: 75px;
    height: 75px;
  }
  .video__more{
    font-size: 18px;
    padding: 15px 20px;
  }
}

/*
---------------- 
     MAP
----------------
*/

.map {
  max-width: 100%;
  background-color: #000;
  position: relative;
}

.map__bg {
  height: 900px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.map__bg::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("assets/images/info-map.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  opacity: 60%;
}

.map__gradient {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: linear-gradient(transparent ,#00000000 60%, #000000 100%);
}

.map__box {
  position: relative;
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  margin-top: 100px;
}

.map__title {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 96px;
  font-weight: 600;
}

.map__slovakia_box {
  z-index: 1;
  position: relative;
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
}

.map__slovakia {
  top: -100px;
  left: 50%;
  width: 90%;
  max-width: 1000px;
  position: absolute;
  transform: translate(-50%);
}

@media only screen and (max-width: 1250px){
  .map__bg {
    height: 800px;
  }
}

@media only screen and (max-width: 1100px){
  .map__title {
    font-size: 80px;
  }
}

@media only screen and (max-width: 900px){
  .map__box {
    margin-top: 50px;
  }
  .map__bg {
    height: 500px;
  }
  .map__title {
    font-size: 68px;
    line-height: 130%;
  }
  .map__slovakia {
    top: -80px;
  }
}

@media only screen and (max-width: 650px){
  
  .map__bg {
    height: 350px;
  }
  .map__title {
    font-size: 32px;
  }
  .map__slovakia {
    top: -40px;
  }
}

/*
---------------- 
     INFO
----------------
*/

.info__box {
  background-color: #000;
}

.info__content {
  z-index: 3;
  position: relative;
  margin-top: -100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  padding-bottom: 100px;
}

.info__flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.info__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 310px;
}

.info__img_bg {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: #D22626;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info__img {
  height: 60px;
  color: #fff;
}

.info__title {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
  line-height: 1;
}

.info__desc {
  font-size: 20px;
  color: #D8D8D8;
  text-align: center;
  padding: 0 10px;
}

.info__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 10px;
  border: 1px solid #D8D8D8;
  border-radius: 10px;
  cursor: pointer;
}

.info__link_img {

}

.info__link_text {
  font-size: 20;
  font-weight: 500;
}

@media only screen and (max-width: 1250px){
  .info__content {
    margin: 0 auto;
    width: 90%;
    gap: 40px;
    padding-bottom: 75px;
    margin-top: -100px;
    flex-direction: column;
    align-items: center;
  }

  .info__flex {
    gap: 50px;
  }
  .info__item {
    gap: 20px;
    width: 310px;
  }
  
  .info__desc {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px){
  .info__content {
    gap: 30px;
    padding-bottom: 35px;
    margin-top: -5px;
  }
  .info__img {
    height: 40px;
  }
  .info__flex {
    gap: 20px;
  }
  .info__item {
    max-width: 250px;
  }
  .info__img_bg {
    width: 100px;
    height: 100px;
  }
  .info__title {
    font-size: 32px;
  }
  .info__desc {
    font-size: 16px;
  }
}

@media only screen and (max-width: 650px){
  .info__content {
    gap: 30px;
    padding-bottom: 35px;
    margin-top: -100px;
  }
  .info__img {
    height: 40px;
  }

  .info__flex {
    gap: 20px;
    flex-direction: column;
  }
}

/*
---------------- 
    ABOUT US
----------------
*/

.about {
  background-color: #000;
  position: relative;
}

.about__bg {

}

.about__bg::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 60%; height: 100%;
  background-image: url("assets/images/about.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  opacity: 90%;
}

.about__bg_gradient1 {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 60%; height: 100%;
  background: linear-gradient(transparent ,#00000000 60%, #000000 100%);
}

.about__bg_gradient2 {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 60%; height: 100%;
  background: linear-gradient(90deg ,transparent ,#00000000 60%, #000000 100%);
}

.about__bg_gradient3 {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 60%; height: 100%;
  background: linear-gradient(0deg ,transparent ,#00000000 60%, #000000 100%);
}

.about__box {
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.about__content {
  padding: 50px 0 75px;
  width: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 30px;
}

.about__title {
  font-size: 48px;
  font-family: 500;
  line-height: 170%;
  color: #fff;
}

.about__text {
  color: #D8D8D8;
  font-size: 20px;
  line-height: 170%;
  text-align: center;
}

.about__diviner {
  height: 50px;
  width: 100%;
  background-color: #000;
  position: relative;
}

@media only screen and (max-width: 1250px){
  .about__box{
    width: 80%;
  }
  .about__content {
    padding: 35px 0 50px;
    width: 400px;
    gap: 15px;
  }
  .about__title {
    font-size: 32px;
  }
  .about__text {
    font-size: 16px;
  }
  .about__diviner {
    height: 35px;
  }
}

@media only screen and (max-width: 900px){
  .about__box{
    width: 100%;
    align-items: center;
  }
  .about__content {
    padding: 35px 0 50px;
    width: 80%;
    gap: 5px;
  }
  .about__title {
    font-size: 32px;
  }
  .about__text {
    font-size: 14px;
  }
  .about__diviner {
    height: 20px;
  }
  .about__bg::before {
    width: 100%;
    opacity: 60%;
  }
  .about__bg_gradient1,
  .about__bg_gradient3{
    width: 100%;
  }
  .about__bg_gradient2{
    opacity: 0;
  }
}

/*
---------------- 
    PROGRAM
----------------
*/

.program {
  background-color: #000;
  position: relative;
}

.program__bg {

}

.program__bg::before {
  content: "";
  position: absolute;
  top: 0; right: 0;
  width: 70%; height: 100%;
  background-image: url("assets/images/program.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  opacity: 90%;
}

.program__bg_gradient1 {
  content: "";
  position: absolute;
  top: 0; right: 0;
  width: 70%; height: 100%;
  background: linear-gradient(transparent ,#00000000 60%, #000000 100%);
}

.program__bg_gradient2 {
  content: "";
  position: absolute;
  top: 0; right: 0;
  width: 70%; height: 100%;
  background: linear-gradient(90deg ,transparent ,#000000 0%, #00000000 40%);
}

.program__bg_gradient3 {
  content: "";
  position: absolute;
  top: 0; right: 0;
  width: 70%; height: 100%;
  background: linear-gradient(0deg ,transparent ,#00000000 60%, #000000 100%);
}

.program__box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  gap: 20px;
  padding: 50px 0 80px;
}

.program__title {
  font-size: 48px;
  font-weight: 500;
  line-height: 50px;
  color: #fff;
}

.program__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1120px;
  width: -webkit-fill-available;
  overflow: hidden;
}

.program__right {
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.program__item {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  width: 320px;
  cursor: pointer;
  z-index: 5;
}

.item__active {
  background-color: #d9d9d9;
}

.program__text {
  padding: 10px 0;
  font-size: 20px;
  line-height: 130%;
  color: #1d1d1d;
}

.program__text_title {
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}

.program__card_box {
  position: absolute;
  top: 0;
  left: 360px;
  right: 0;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  gap: 20px;
  border-radius: 0 10px 10px 0;
}

.program__relative {
  position: relative;
  max-width: var(--page-width);
  width: -webkit-fill-available;
}

.program__last{
  bottom: 0;
  top: auto;
}

.program__hide {
  display: none;
}

.program__card_img {
  height: 160px;
}

.program__card_content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}

.program__card_title {
  font-size: 20px;
  text-transform: uppercase;
  line-height: 170%;
  font-weight: bold;
}

.program__card_text {
  font-size: 16px;
  line-height: 170%;
}
.item__active>.program__text {
  color: #fff;
}
.item__active>.program__text>.program__text_title {
  color: #fff;
}
.item__active{
  z-index: 5;
  background-color: #D22626;
}
.program__first_item {
  border-radius: 10px 10px 0 0;
}
.program__first_item.item__active{
  border-radius: 0 0 0 0;
}
.program__last_item {
  border-radius: 0 0 10px 10px;
}
.program__last_item.item__active{
  border-radius: 0 0 0 0;
}
.program__card_img.program__last_item {
  border-radius: 0 0 0 0;
}
.program__card_box.program__last_item {
  border-radius: 0 10px 10px 0;
}

@media only screen and (max-width: 1250px){
  .program__box {
    gap: 10px;
    padding: 35px 0 50px;
  }
  .program__title {
    font-size: 32px;
  }
  .program__item {
    padding: 10px 20px;
    max-width: 250px;
  }
  .program__text {
    font-size: 16px;
  }
  .program__card_box {
    left: 290px;
  }
  .program__card_img {
    height: 130px;
  }
  .program__card_title {
    font-size: 16px;
  }
  .program__card_text {
    font-size: 14px;
  }
  .program__last_item.item__active{
    border-radius: 0 0 0 0;
  }
}

@media only screen and (max-width: 900px){
  .program__box {
    gap: 10px;
    padding: 35px 0 50px;
    justify-content: center;
    align-items: center;
  }
  .program__title {
    font-size: 32px;
    text-align: center;
  }
  .program__content {
    justify-content: center;
    align-items: center;
  }
  .program__first_item {
    border-radius: 10px 10px 0 0;
  }
  .program__last_item {
    border-radius: 0 0 10px 10px;
  }
  .program__item {
    padding: 10px 20px;
    max-width: -webkit-fill-available;
    width: 500px;
  }
  .program__relative {
    width: 500px;
  }
  .program__text {
    font-size: 16px;
  }
  .program__card_box {
    padding: 10px 20px;
    position: initial;
    border-radius: 0 0 0 0;
  }
  .program__card_img {
    height: 130px;
  }
  .program__card_title {
    display: none;
  }
  .program__card_text {
    font-size: 14px;
  }
  .program__first_item.item__active{
    border-radius: 10px 10px 0 0;
  }
  .program__card_box.program__last_item {
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 600px){
  .program__relative {
    width: 90%;
  }
  .program__card_box {
    position: initial;
    flex-direction: column-reverse;
  }
  .program__card_box {
    padding: 10px 0 0;
  }
  .program__card_text {
    margin: 0 20px;
  }
  .program__card_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .program__card_title {
    display: none;
  }
  .program__card_img.program__last_item {
    border-radius: 0 0 10px 10px;
  }
}

/*
---------------- 
    OPENDAY
----------------
*/

.openday {
  width: 100%;
  background-color: #090B0F;
}

.openday__box {
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.openday__content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.openday__title {
  font-size: 40px;
  font-weight: 500;
  line-height: 130%;
  color: #fff;
  text-align: center;
}

.openday__text {
  font-size: 20px;
  line-height: 170%;
  text-align: center;
  color: #D8D8D8;
}

.openday__img {
  width: 50%;
}

@media only screen and (max-width: 1250px){
  .openday__title {
    font-size: 32px;
  }
  .openday__text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px){
  .openday__box{
    flex-direction: column;
    text-align: center;
    gap: 35px;
    padding: 35px 0;
  }
  .openday__img,
  .openday__content{
    width: 100%;
  }
  .openday__title {
    font-size: 32px;
  }
  .openday__text {
    font-size: 14px;
  }
}

/*
---------------- 
  SPECIAL DAYS
----------------
*/

.days {
  background-color: #000;
}

.days__box {
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 50px 0 80px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.days__title {
  font-size: 70px;
  font-weight: 500;
  line-height: 130%;
  color: #fff;
}

.days__content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.days__content_img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.days__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
}

.days__img {
  width: 100%;
  height: 430px;
}

.days__item_title {
  font-size: 48px;
  font-weight: 500;
  line-height: 170%;
  color: #fff;
}

.days__item_text {
  font-size: 20px;
  color: #D8D8D8;
}

@media only screen and (max-width: 1250px){
  .days__box {
    gap: 5px;
    padding: 35px 0;
  }
  .days__title {
    font-size: 48px;
  }
  .days__content {
    gap: 60px;
  }
  .days__item {
    max-width: 300px;
  }
  .days__img {
    width: 100%;
    height: auto;
  }
  .days__item_title {
    font-size: 32px;
  }
  .days__item_text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px){
  .days__box {
    gap: 20px;
    padding: 35px 0;
  }
  .days__title {
    font-size: 40px;
  }
  .days__content {
    gap: 20px;
    flex-direction: column;
  }
  .days__content_img {
    gap: 15px;
  }
  .days__item {
    width: 100%;
  }
  .days__img {
    max-width: 200px;
    height: auto;
  }
  .days__item_title {
    font-size: 28px;
  }
  .days__item_text {
    font-size: 16px;
  }
}

/*
---------------- 
    PACKING
----------------
*/

.packing {
  background-color: #000;
  position: relative;
}

.packing__bg {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("assets/images/packing.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 30%;
}

.packing__box {
  position: relative;
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.packing__title {
  font-size: 40px;
  font-weight: 500;
  line-height: 170%;
  color: #fff;
}

.packing__content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}

.packing__item {
  padding: 0 30px;
  width: 30%;
}

.packing__item_title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  line-height: 170%;
  padding-bottom: 10px;
}

.packing__list {
  font-size: 20px;
  font-weight: 500;
  line-height: 170%;
  color: #fff;
}

@media only screen and (max-width: 1250px){
  .packing__box {
    padding: 35px;
    gap: 15px;
  }
  
  .packing__title {
    font-size: 32px;
  }
  
  .packing__content {
    gap: 10px;
  }
  
  .packing__item {
    padding: 0 15px;
  }
  
  .packing__item_title {
    font-size: 24px;
  }
  
  .packing__list {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px){
  .packing__box {
    padding: 35px 0;
    gap: 15px;
  }
  
  .packing__title {
    font-size: 32px;
  }
  
  .packing__content {
    gap: 20px;
    flex-direction: column;
  }
  
  .packing__item {
    padding: 0 15px;
    width: 100%;
  }
  
  .packing__item_title {
    font-size: 18px;
    text-align: center;
  }
  
  .packing__list {
    font-size: 14px;
  }
}

/*
---------------- 
    PHOTOS
----------------
*/

.photos {
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
}

.photos__box {
  display: flex;
}

.photos__img {
  width: 50%;
  object-fit: cover;
}

.photos__flex {
  width: 50%;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 900px){
  .photos {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
  .photos__img {
    width: 100%;
  }
  .photos_off {
    display: none;
  }
  .photos__flex {
    display: flex;
    flex-direction: column;
  }
}

/*
---------------- 
  REGISTRATION
      CARD
----------------
*/

.regcard {
  display: flex;
  justify-content: center;
  position: relative;
}

.regcard__box {
  width: 740px;
  position: absolute;
  top: -140px;
  padding: 10px 0 20px;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000025;
}

.regcard__title {
  font-size: 64px;
  font-weight: 600;
  color: #000;
}

.regcard__text {
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  line-height: 170%;
  color: #1d1d1d;
}

@media only screen and (max-width: 1250px){
  .regcard__box {
    width: 600px;
    padding: 10px 0 20px;
  }
  .regcard__title {
    font-size: 48px;
    line-height: 170%;
  }
  .regcard__text {
    font-size: 18px;
    line-height: 170%;
  }
}

@media only screen and (max-width: 900px){
  .regcard__box {
    width: 90%;
    padding: 10px 0 15px;
  }
  .regcard__title {
    font-size: 32px;
    line-height: 170%;
  }
  .regcard__text {
    margin: 0 20px;
    font-size: 16px;
    line-height: 170%;
  }
}

/*
---------------- 
  REGISTRATION
----------------
*/

.reg {
  width: 90%;
  max-width: 930px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000025;
}

.reg__type_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg__type {
  width: 50%;
  background-color: #fff;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reg__type_title {
  font-size: 32px;
  font-weight: 600;
  line-height: 170%;
  color: #000;
  text-align: center;
}

.reg__active {
  color: #fff;
  background-color: #D22626;
}

.reg__border_1 {
  border-radius: 10px 0 0 0;
}

.reg__border_2 {
  border-radius: 0 10px 0 0;
}

.reg__form {
  padding: 50px 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 40px;
}

.reg__form_box {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.reg__form_half {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reg__form_label {
  font-size: 20px;
  font-weight: 600;
  line-height: 170%;
  color: #1d1d1d;
}

.reg__success>.reg__form_label{
  font-weight: 400;
  text-align: center;
}

.reg__form_red {
  color: #D22626;
}

.reg__form_input {
  padding: 10px;
  padding-left: 20px;
  font-size: 20px;
  line-height: 170%;
  color: #1d1d1d;
  background-color: #f1f1f1;
  border: 1px solid #707070;
  border-radius: 10px;
  width: -webkit-fill-available;
}

select.reg__form_input {
  padding: 15px;
}

.reg_pad{
  padding: 9px 10px;
  padding-left: 20px;
}

.hr {
  width: 100%;
}

.reg__submit_box {
  display: flex;
  justify-content: center;
  align-content: center;
}

.reg__submit{
  width: min-content;
  font-size: 20px;
  font-weight: 600;
  font-family: "Saira Condensed",sans-serif;
  text-transform: uppercase;
  color: #fff;
  line-height: 170%;
  padding: 5px 40px;
  margin: 0;
  background-color: #D22626;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}

.reg__info {
  font-size: 18px;
}

.reg__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
}

.error {
  color: red;
}
.success {
  color: green;
}

@media only screen and (max-width: 1250px){
  .reg {
    margin-top: 75px;
    margin-bottom: 35px;
  }
  .reg__type_title {
    font-size: 28px;
  }
  .reg__form {
    padding: 50px;
    gap: 30px;
  }
  .reg__form_box {
    gap: 50px;
  }
  .reg__info {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px){
  .reg {
    margin-top: 50px;
    margin-bottom: 35px;
  }
  .reg__type_title {
    font-size: 20px;
    line-height: 130%;
  }
  .reg__form {
    padding: 35px;
    gap: 30px;
  }
  .reg__form_box {
    gap: 15px;
    flex-direction: column; 
    align-items: center;
  }
  .reg__form_half {
    width: 100%;
    max-width: 400px;
  }
  .reg__form_label {
    font-size: 16px;
  }
  .reg__form_input {
    font-size: 16px;
  }
  .reg__submit {
    font-size: 18px;
  }
  .reg__info {
    font-size: 14px;
  }
}

/*
---------------- 
    FOOTER
----------------
*/

.footer {
  width: 100%;
  background-color: #11161d;
}

.footer__box {
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;
}

.footer__title {
  font-size: 32px;
  font-weight: normal;
  color: #fff;
  line-height: 170%;
}

.footer__kontakt {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.footer__card {
  width: 210px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer__card_img {
  height: 28px;
  color: #D22626;
}

.footer__card_title {
  font-size: 20px;
  font-weight: 600;
  line-height: 170%;
  color: #000;
}

.footer__card_text {
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  color: #343434;
  text-align: center;
}

.footer__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.footer__social_card {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer__social_img {
  height: 15px;
  color: #D22626;
}

.copyright__box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #090b0f;
  padding: 10px 0;
}

.copyright__text {
  font-size: 14px;
  font-weight: normal;
  color: #969696;
  line-height: 170%;
  text-align: center;
}

.copyright__link{
  font-weight: bold;
  color: #969696;
  cursor: pointer;
}

@media only screen and (max-width: 1250px){
  .copyright__text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 900px){
  .footer__title {
    font-size: 32px;
    text-align: center;
  }
  .footer__kontakt {
    flex-direction: column;
  }
  .copyright__text {
    font-size: 8px;
  }
}